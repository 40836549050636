<template>
    <v-dialog v-model="dialog" persistent width="500" scrollable>
        <v-card>
            <v-card-title>
                <span class="headline">{{editMode ? "Editar" : "Novo"}} Usuário</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-form ref="form1" v-model="form1Valid" lazy-validation>
                        <v-row>
                            <v-col>
                                <v-text-field v-model="usuario.nome" label="Nome"
                                              placeholder=" " :rules="requiredRules"
                                              required></v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field v-model="usuario.sobrenome" label="Sobrenome"
                                              placeholder=" "></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field v-model="usuario.email" label="Email"
                                              placeholder=" " :rules="emailRules" required></v-text-field>
                            </v-col>
                            <v-col cols="4" v-if="editMode">
                                <v-switch v-model="usuario.active" :false-value="true" :true-value="false" label="Inativo" color="red darken-1"></v-switch>
                            </v-col>
                        </v-row>
                        <v-row v-if="!editMode">
                            <v-col cols="4">
                                <v-text-field ref="senha" outlined v-model="usuario.senha"
                                              label="Senha" readonly>
                                    <template v-slot:append>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-icon v-on="on" @click="copyText">mdi-content-copy</v-icon>
                                            </template>
                                            Copiar senha
                                        </v-tooltip>
                                    </template>
                                </v-text-field>
                            </v-col>
                            <v-col cols="8">
                                <span class="">A senha será gerada automaticamente e o usuário terá que cadastrar uma nova quando fizer o primeiro acesso</span>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <div class="pa-3" style="width: 100%">
                    <v-btn text @click="closeDialog">Cancelar</v-btn>

                    <div class="float-right">
                        <v-btn color="primary" text @click="save">Salvar</v-btn>
                    </div>

                </div>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {Usuario} from '../../../models/Usuario';
    import {UsuarioQueries} from '../../../queries/UsuarioQueries';

    export default {
        name: 'NewUsuarioDialog',
        data() {
            return {
                dialog: false,
                editMode: false,
                form1Valid: true,
                requiredRules: [
                    (v) => !!v || 'Campo obrigatório',
                ],
                emailRules: [
                    (v) => !!v || 'E-mail é obrigatório',
                    (v) => /.+@.+\..+/.test(v) || 'E-mail deve ser válido',
                ],
                usuario: new Usuario(),
            };
        },
        methods: {
            openDialog(usuario) {
                this.dialog = true;
                if (usuario) {
                    this.editMode = true;
                    this.usuario = this._.cloneDeep(usuario);
                } else {
                    this.editMode = false;
                    this.usuario = new Usuario();
                    this.usuario.senha = this.generateRandomPassword(12);
                }
            },
            closeDialog() {
                this.dialog = false;
                this.usuario = new Usuario();
                this.$refs.form1.reset();

            },
            save() {
                if (this.$refs.form1.validate()) {
                    if (!this.editMode) {
                        this.$apollo.mutate({
                            mutation: UsuarioQueries.save,
                            variables: {
                                usuario: this.parseRequest(this.usuario),
                            },
                        }).then((result) => {
                            this.$emit('saved', Object.assign({}, result.data.saveUsuario));
                            this.closeDialog();
                        });
                    } else {
                        this.$apollo.mutate({
                            mutation: UsuarioQueries.update,
                            variables: {
                                id: this.usuario.id,
                                usuario: this.parseRequest(this.usuario),
                            },
                        }).then((result) => {
                            this.$emit('updated', Object.assign({}, result.data.updateUsuario), this.usuario.index);
                            this.closeDialog();
                        });
                    }
                }
            },
            parseRequest(usuario) {
                const usuarioParsed = this._.cloneDeep(usuario);

                delete usuarioParsed.index;
                delete usuarioParsed.id;
                delete usuarioParsed.imagem;
                delete usuarioParsed.createdAt;
                delete usuarioParsed.updatedAt;
                delete usuarioParsed.admin;

                return usuarioParsed;
            },
            generateRandomPassword(length) {
                let result           = '';
                const characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%&*()-_=+|\/\\';
                const charactersLength = characters.length;
                for ( let i = 0; i < length; i++ ) {
                    result += characters.charAt(Math.floor(Math.random() * charactersLength));
                }

                return result;
            },
            copyText() {
                const textToCopy = this.$refs.senha.$el.querySelector('input');
                textToCopy.select();
                document.execCommand('copy');
            },
        },
    };
</script>

<style scoped>

</style>