import {Image} from '@/models/Image';

export class Usuario {
    public id: number;
    public nome: string;
    public sobrenome: string;
    public email: string;
    public admin: boolean;
    public active: boolean;
    public senha: string;
    public imagem: Image;
}
