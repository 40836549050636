import gql from "graphql-tag";
<template>
    <page title="Usuários">
        <v-breadcrumbs slot="breadcrumb" class="pa-0">
            <v-breadcrumbs-item exact :to="{name:'dashboard'}">Início</v-breadcrumbs-item>
        </v-breadcrumbs>
        <v-card class="mt-3">
            <v-list two-line v-if="usuarios && usuarios.length > 0">
                <template v-for="(usuario, index) in usuarios">
                    <v-list-item>
                        <v-list-item-avatar color="blue darken-1">
                            <usuario-avatar :usuario="usuario" />
                        </v-list-item-avatar>

                        <v-list-item-content>
                            <v-list-item-title>{{usuario.nome}} {{usuario.sobrenome}}</v-list-item-title>
                            <v-list-item-subtitle v-html="usuario.email"></v-list-item-subtitle>
                            <div>
                                <v-chip v-if="!usuario.active"
                                        x-small label
                                        color="red darken-1" text-color="white"
                                        class="font-weight-bold">
                                    <span>Inativo</span>
                                </v-chip>
                            </div>

                        </v-list-item-content>

                        <v-list-item-action class="align-self-center">
                            <v-list-item-action-text >Data Criação</v-list-item-action-text>
                            <v-list-item-action-text >{{new Date(usuario.createdAt) | moment("DD MMM YYYY")}}</v-list-item-action-text>
                        </v-list-item-action>
                        <v-list-item-action class="mt-0">
                            <v-menu bottom left >
                                <template v-slot:activator="{ on }">
                                    <v-btn icon v-on="on">
                                        <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                </template>

                                <v-list>
                                    <v-list-item @click="editUsuario(usuario, index)">
                                        <v-list-item-title>Editar</v-list-item-title>
                                    </v-list-item>
                                    <!--<v-list-item @click="">
                                        <v-list-item-title>Definir nova senha</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item @click="">
                                        <v-list-item-title>Bloquear</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item @click="">
                                        <v-list-item-title>Apagar</v-list-item-title>
                                    </v-list-item>-->
                                </v-list>
                            </v-menu>
                        </v-list-item-action>
                    </v-list-item>
                    <v-divider ></v-divider>
                </template>
            </v-list>
            <v-overlay :value="loading" color="white" absolute opacity="0.8" z-index="1" />
            <v-progress-linear :active="loading" :indeterminate="loading"
                               absolute top color="secondary" />
        </v-card>
        <v-btn fixed dark fab large bottom right color="secondary" @click="openNewDialog()">
            <v-icon>mdi-plus</v-icon>
        </v-btn>

        <new-usuario-dialog ref="newUsuarioDialog" @saved="onUsuarioSaved" @updated="onUsuarioEdited" />
    </page>
</template>

<script>
    // @ts-ignore
    import page from '../../../components/Page.vue';
    // @ts-ignore
    import NewUsuarioDialog from './NewUsuarioDialog';
    // @ts-ignore
    import UsuarioAvatar from '../../../components/UsuarioAvatar';
    import gql from 'graphql-tag';
    export default {
        name: 'Index',
        components: {
            UsuarioAvatar,
            NewUsuarioDialog,
            page,
        },
        data() {
            return {
                usuarios: [],
                loading: true,
            };
        },
        methods: {
            openNewDialog() {
                this.$refs.newUsuarioDialog.openDialog();
            },
            editUsuario(usuario, index) {
                usuario.index = index;
                this.$refs.newUsuarioDialog.openDialog(usuario);
            },
            getUsuarios() {
                this.loading = true;

                this.$apollo.query({
                    query: gql`query {
                        usuarios(withInatives: true){
                            id
                            nome
                            sobrenome
                            email
                            active
                            createdAt
                            admin
                            imagem{
                                id
                            }
                        }
                    }`
                }).then((result) => {
                    this.usuarios = result.data.usuarios;
                    this.loading = false;
                });
            },
            onUsuarioSaved(usuario) {
                this.usuarios.unshift(usuario);
            },
            onUsuarioEdited(usuario, index) {
                let usuarioExisted = this.usuarios[index];
                usuarioExisted = Object.assign(usuarioExisted, usuario);
            },
        },
        mounted() {
            document.title = "Usuários - JF Assessoria Rural"
            this.getUsuarios();
        },
    };
</script>

<style scoped>

</style>